import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getMacroGoal = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'macro-goal',
    method: 'get'
  })

  return data
}

const putMacroGoal = async (requestBody = {}) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'macro-goal',
    method: 'put',
    body: requestBody
  })

  return data
}

export default { getMacroGoal, putMacroGoal }
