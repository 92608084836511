import { useForm } from 'react-hook-form'

import { Navigation, Input, If } from '@itau-loans-www/shopping/src/components'
import ProposalLayout from 'layouts/Proposal'

import Shimmer from 'components/Shimmer'

import useSelectGoalCustomize from './hooks'

const Customize = ({ pageContext }) => {
  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: { goal: '' }
  })

  const { onSubmit, loading } = useSelectGoalCustomize()

  return (
    <ProposalLayout pageContext={pageContext}>
      <If
        condition={loading}
        renderIf={
          <>
            <Shimmer margin="10px 0 0" width="40%" height="30px" />
            <Shimmer margin="8px 0 32px 0" widthDesktop="72%" height="30px" />
            <Shimmer margin="0 0 20px 0" height="50px" />
            <Shimmer
              margin="350px 0 0 0"
              marginDesktop="300px 0 0 0"
              widthDesktop="50%"
              height="50px"
            />
          </>
        }
        renderElse={
          <>
            <h1 className="proposal-title">Qual é o seu objetivo?</h1>
            <p className="proposal-subtitle">
              Digite em poucas palavras qual a finalidade do empréstimo
            </p>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <Input.Text
                label="objetivo"
                id="goal"
                name="goal"
                maxLength="50"
                control={control}
              />
              <Navigation
                submit
                nextStep
                isNextButtonDisabled={!isValid}
                nextButtonId="select-goal-customize-next-btn"
              />
            </form>
          </>
        }
      />
    </ProposalLayout>
  )
}

export default Customize
